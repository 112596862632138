<script>
  import {
    required,
    email,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";
  import { mapActions, mapGetters} from "vuex";
  import appConfig from "../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import serverConfig from '@/helpers/config';

  export default {
    name: "addCustomer",
    emits: ["getParent"], 
    props: [
    'ID',
    'projectID',
    'type',
    ],
    setup() {
        let tk = 'Bearer '+localStorage.getItem('tk')      
        return {  tk, v$: useVuelidate()};
    },
    page: {
      title: "Utenti",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Utenti",
        createNew:{
          status: "init",
          CodCliFor:0,
          TipoSoggetto:"",
          ragSoc:"",
          nome:"",
          cognome:"",
          dataDiNascita:"",
          luogoDiNascita:"",
          sigla:"",
          codiceFiscale:"",
          codeDest:"",
          email:"",
          pec:"",
          telefono:"",
          partitaIva:"",
          codiceAteco:"",
          nRea:"",
          artigiano:"",
          indirizzo:"",
          citta:"",
          cap:"",

        },
        dataEdit:{
          status: "init",
          CodCliFor:0,
          TipoSoggetto:"",
          ragSoc:"",
          nome:"",
          cognome:"",
          dataDiNascita:"",
          luogoDiNascita:"",
          sigla:"",
          codiceFiscale:"",
          codeDest:"",
          email:"",
          pec:"",
          telefono:"",
          partitaIva:"",
          codiceAteco:"",
          nRea:"",
          artigiano:"",
          indirizzo:"",
          citta:"",
          cap:"",

        },
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,

        test:{},
        checkUserdataInEs:false,
        retrCode:0,
        codiceConfermato:0,
        optionsModPayConfigList:[],
        
      };
    },
    validations: {
      dataEdit: {
        TipoSoggetto:{
          required: helpers.withMessage("Tipo soggetto obbligatorio", required),
        },
        ragSoc:{
          required: helpers.withMessage("Ragione sociale obbligatoria", required),
        },
        nome: {
          required: helpers.withMessage("Nome obbligatorio", required),
        },
        cognome: {
          required: helpers.withMessage("Campo Cognome obbligatorio", required),
        },
        email: {
          required: helpers.withMessage("Campo Email obbligatorio", required),
          email: helpers.withMessage("Per favore, inserisci una email valida", email),
        },
        codiceFiscale: {
          required: helpers.withMessage("Campo Codice Fiscale obbligatorio", required),
        },
        partitaIva: {
          required: helpers.withMessage("Campo Partita Iva obbligatorio", required),
        },
        indirizzo: {
          required: helpers.withMessage("Campo Indirizzo obbligatorio", required),
        },
        citta: {
          required: helpers.withMessage("Campo Città obbligatorio", required),
        },
        cap: {
          required: helpers.withMessage("Campo CAP obbligatorio", required),
        },
        provincia: {
          required: helpers.withMessage("Campo Provincia obbligatorio", required),
        },
        telefono: {
          required: helpers.withMessage("Campo Telefono obbligatorio", required),
        },
        CodCondizPagamento: {
          required: helpers.withMessage("Campo Modalità di pagamento obbligatorio", required),
        },
      },
    },
    computed: {
      ...mapGetters('settings', ['getSettingsRole','getSettingsArea']),

    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    
    beforeMount() {
     
    },
    methods: {
      ...mapActions('auth', ['refreshToken','clearSessionStorage']),
      ...mapActions('settings', ['getSettings_userolearea',]),
      ...mapActions('layout', ['changeLayoutType', ]),
        axiosInterceptor(){
          axios.interceptors.response.use(
                (response) => {
                    
                    return response;
                },
                (error) => {
                    if (error.response && error.response.status === 401) {
                      Swal.fire({
                        title:"Accesso non autorizzato",
                        text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                        icon:"warning",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:3500,
                      })
                        this.$router.push('/login')
                        if(localStorage.getItem('tk')){
                          localStorage.removeItem('tk')
                        }
                        return Promise.reject('Unauthorized access');
                    } else if (error.response && error.response.status  === 404) {
                        Swal.fire({
                          title:"Nessun risultato trovato",
                          text:"Mi spiace, nessun risultato trovato",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:2000,
                        })
                        return Promise.reject('The server cannot find the requested resource');
                    }  else if (error.response && error.response.status  === 412) {
                      Swal.fire({
                        title:"Credenziali mancanti",
                        text:"Inserisci delle credenziali valide, per favore.",
                        icon:"warning",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                      return Promise.reject('The server cannot find the requested resource');
                  } else if (error.response && error.response.status  === 423) {
                        Swal.fire({
                          title:"Risorsa non accessibile",
                          text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:2000,
                        })
                        this.$router.push('/login')
                        if(localStorage.getItem('tk')){
                          localStorage.removeItem('tk')
                        }                
                        return Promise.reject('The server cannot find the requested resource');
                    }
                    else if (error.response && error.response.status  === 500) {
                      Swal.fire({
                        title:"Errore Server",
                        text:"Mi spiace, ma riscontriamo degli errori sul server.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                        return Promise.reject('Internal Server Error');
                    }
                    else if (error.response && error.response.status  === 502) {
                        Swal.fire({
                          title:"Errore Server",
                          text:"Mi spiace, ma riscontriamo degli errori sul server.",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:2000,
                        })
                        return Promise.reject('Bad Gateway');
                    }
                    else if (error.response && error.response.status  === 503) {
                      Swal.fire({
                        title:"Servizio non disponibile",
                        text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                        return Promise.reject('Service Unavailable');
                    } else {
                      console.log(error.response.status )
                      Swal.fire({
                        title:"Errore imprevisto",
                        text:"Ops! si è verificato un problema...riprova, per favore.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:2000,
                      })
                      
                    }

                }
              );
        },
        showModal(){
          document.getElementById("closemodal").click();
        },
        getItem(id) {
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}customers/${id}`, 
            {headers: { authorization:this.tk}} )
            .then((response) => {
                this.dataEdit = response.data.customerData
                this.getModPayConfigList()
          })
        },
        getModPayConfigList() {
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}esync/get-mod-pay-config`, 
        {headers: { authorization:this.tk}} )
        .then((response) => {
            if (response) {
              if (response.data.status=='data') {
                this.optionsModPayConfigList = response.data.results
                this.optionsModPayConfigList.sort(function (a, b) {
                  if (a.Des < b.Des) {
                    return -1;
                  }
                  if (a.Des > b.Des) {
                    return 1;
                  }
                  return 0;
                });

              } else {
                Swal.fire({
                    title: "Errore di configurazione",
                    text: "Non è possibile ottenere i dati di configurazione delle modalità di pagamento provenienti dal gestionale.",
                    icon: 'error',
                    timer:2000,
                  })
              }
            }
            
        })
      },
      modPayFilter() {
        if ( this.optionsModPayConfigList.length>0 ) {
          let self = this
          let payList = this.optionsModPayConfigList.filter(function(el){
            return el.CodCondizPagamento == self.dataEdit.CodCondizPagamento
          })
          if (payList) {
            this.dataEdit.DesCondizPagamento = payList[0].Des
          }
        }
      },
      updatedata() {
        this.submitted = true;
        this.v$.$touch()
        this.modPayFilter()
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}customers/register-in-es`, this.dataEdit, 
         {headers: { authorization:this.tk}} )
          .then((response) => {
            if(response.data){
              if(response.data.result){
                if (response.data.result.status == 'data-complete') {      
                  Swal.fire({
                    title:"Anagrafica già presente",
                    text:"Dal codice fiscale o dalla partita iva da te inserita, sembra che il cliente abbia già un'anagrafica nel nostro sistema. Utilizza il modulo 'Seleziona cliente' per assegnare l'anagrafica a questo progetto.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:5000,
                  })
                } 
                if  (response.data.result.status == 'OK') { 

                  
                  Swal.fire(  this.$t("t-msg-new-customer-reg-status") , this.$t("t-msg-new-customer-reg-content"), "success");
                  
                  if (response.data.result.response.customerUpdate.modifiedCount == 1 && response.data.result.response.resultES==200) {
                  Swal.fire(  this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
                  }
                  if (response.data.result.response.customerUpdate.modifiedCount == 0 && response.data.result.response.resultES==200) {
                    Swal.fire(  this.$t("t-msg-partially-updated-es") , this.$t("t-msg-partially-updated-content-es"), "warning");
                  }
                  if (response.data.result.response.customerUpdate.modifiedCount == 1 && response.data.result.response.resultES!=200) {
                    Swal.fire(  this.$t("t-msg-partially-updated-app") , this.$t("t-msg-partially-updated-content-app"), "warning");
                  }
                
                  if (this.type=='project'){
                    this.updateProject(this.dataEdit)
                  }
                }
              }
            }

          })
      },
      updateProject(params){
        let data = {}
        data.customerID = this.ID
        data.customerDetails = params
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}projects/${this.projectID}`, data, {headers: { authorization:this.tk}} )
          .then((response)=>{
            if (response.data){
              this.$emit('getParent')
              this.getItem()
            }
          })
      },
      initItem(){
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}customers/register`,this.createNew, {headers: { authorization:this.tk}} )
          .then((response)=>{
              if (response.data) {
                
                this.dataEdit._id = response.data._id
                this.getItem(response.data._id)
                this.registerSuccess = true
               
              }  
            }).catch(()=>{
              this.regError = true
            })
      },
      validaCodiceFiscale(cf)
        {
          var validi, i, s, set1, set2, setpari, setdisp;
          if( cf == '' )  return '';
          cf = cf.toUpperCase();
          if( cf.length != 16 )
            return false;
          validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
          for( i = 0; i < 16; i++ ){
            if( validi.indexOf( cf.charAt(i) ) == -1 )
              return false;
          }
          set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
          setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
          s = 0;
          for( i = 1; i <= 13; i += 2 )
            s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
          for( i = 0; i <= 14; i += 2 )
            s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )));
          if( s%26 != cf.charCodeAt(15)-'A'.charCodeAt(0) )
            return false;
          return true;
        },
        controllaCF(codfis){
            if (codfis!='')
              {
                if (this.validaCodiceFiscale(codfis) == false) {
                  document.getElementById('codiceFiscale').setAttribute('style','border:1px solid red;border-radius:4px')
                  document.getElementById('errCodFisc').innerHTML = "<label style='color:red'>Codice fiscale non valido</label>";
                  Swal.fire({
                    title: "Campo non valido",
                    text: "Il codice fiscale inserito non risulta corretto",
                    icon: 'error',
                    timer:2000,
                  })

              } else {
                document.getElementById('errCodFisc').innerHTML = ""
                document.getElementById('codiceFiscale').setAttribute('style','border:2px solid #eee;')
              
                this.checkUserdataByCode(codfis)

              }
            } else {
              document.getElementById('errCodFisc').innerHTML = ""
              document.getElementById('codiceFiscale').setAttribute('style','border:2px solid #eee;')
            }
        },
        controllaPartitaIVA(pi) {

          if (pi == '') {
            document.getElementById('partitaIva').setAttribute('style','border:1px solid red;border-radius:4px')
            document.getElementById('errPIva').innerHTML = "<label style='color:red'>Inserisci Partita Iva corretta</label>";
          Swal.fire({
            title: "Campo non valido",
            text: "La Partita Iva inserita non risulta corretta",
            icon: 'error',
            timer:2000,
          })
          } 
          else if (!/^[0-9]{11}$/.test(pi))
          {
            document.getElementById('partitaIva').setAttribute('style','border:1px solid red;border-radius:4px')
            document.getElementById('errPIva').innerHTML = "<label style='color:red'>Inserisci Partita Iva corretta</label>";
          Swal.fire({
            title: "Campo non valido",
            text: "La Partita Iva inserita non risulta corretta",
            icon: 'error',
            timer:2000,
          })
          } else {
          var s = 0;
          for (let i=0; i<=9; i+=2) {
            s += pi.charCodeAt(i) - '0'.charCodeAt(0);
          }
          for (let i=1; i<=9; i+=2 ){
            var c = 2*(pi.charCodeAt(i) - '0'.charCodeAt(0));
            if (c > 9) c = c - 9;
            s += c;
          }
          var controllo = (10 - s%10)%10;
          if (controllo != pi.charCodeAt(10) - '0'.charCodeAt(0)){
            document.getElementById('errPIva').innerHTML = "";
            document.getElementById('partitaIva').setAttribute('style','border:2px solid #eee;')
          }
          this.checkUserdataByCode(pi)
          
          }
        },
        checkUserdataByCode(code){           
          if (code.length >= 11)
          {
          
            if (this.tk) {
              let q = code.trim()
              this.check_code = q
              try {
                let url = `${serverConfig.EP}/customers/get-userdata-from-es`;
                axios.defaults.headers.common['authorization'] = this.tk
                let data = {
                  id:this.dataEdit._id,
                  code: this.check_code,
                }
                axios.post(url,data).then((response) => {
                    this.checkUserdataList = response.data
                    if (response.data.status == 'data' || response.data.status == 'data-in-es' ) {
                      this.checkUserdataInEs = true
                      if (response.data.results.length>0){
                        this.retrCode = response.data.results[0].CodCliFor
                        Swal.fire({
                          title:"Anagrafica presente nel gestionale",
                          text:"Dal codice fiscale o dalla partita iva da te inserita, sembra che il cliente abbia già un'anagrafica nel gestionale in uso.",
                          icon:"info",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:4000,
                        })
                      }
                    }  
                    
                    else if (response.data.status == 'data-complete') {
                      this.checkUserdataInEs = false
                      Swal.fire({
                          title:"Anagrafica già presente",
                          text:"Dal codice fiscale o dalla partita iva da te inserita, sembra che il cliente abbia già un'anagrafica nel nostro sistema. Utilizza il modulo 'Seleziona cliente' per assegnare l'anagrafica a questo progetto.",
                          icon:"error",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:5000,
                        })
                    }
                    else if (response.data.status == 'data-db-app') {
                      this.checkUserdataInEs = false
                      Swal.fire({
                          title:"Anagrafica da registrare nel gestionale",
                          text:"Dal codice fiscale o dalla partita iva da te inserita, sembra che il cliente abbia già un'anagrafica nell'applicazione, ma non ancora inviata al gestionale. E' necessario procedere con la registrazione completa al sistema",
                          icon:"warning",
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:4000,
                        })
                    }
                     else {
                      this.checkUserdataInEs = false
                    }
                })
              } catch {
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
              }

            }
          }

        },
        swlDataImport() {
          if( parseInt(this.codiceConfermato)>0 ){
            if (parseInt(this.codiceConfermato)==parseInt(this.retrCode))
            {
              let url = `${serverConfig.EP}/customers/check-and-save-userdata-from-es`;
              axios.defaults.headers.common['authorization'] = this.tk
              let data = {
                id:this.dataEdit._id,
                code:this.retrCode,
                piva:this.check_code,
              }
              
              axios.post(url,data,
                {
                  onUploadProgress: (event) =>
                  {
                    const percentUploaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                    let timerInterval;
                    Swal.fire({
                      title: "Caricamento...",
                      timer: percentUploaded,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading();
                       // const timer = Swal.getPopup().querySelector("b");
                        /**
                          timerInterval = setInterval(() => {
                            timer.textContent = `${Swal.getTimerLeft()}`;
                          }, 100);
                        */
                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    }).then((result) => {
                      /* Read more about handling dismissals below */
                      if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('Caricamento...')
                      }
                    });

                  },
                  onDownloadProgress: (event) =>
                  {
                    const percentDownloaded = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
                    let timerInterval;
                    Swal.fire({
                      title: "Download data..",
                      timer: percentDownloaded,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading();
                        //const timer = Swal.getPopup().querySelector("b");
                        /**
                         *timerInterval = setInterval(() => {
                            timer.textContent = `${Swal.getTimerLeft()}`;
                            }, 100);
                         */

                      },
                      willClose: () => {
                        clearInterval(timerInterval);
                      }
                    }).then(() => {
                      
                    });

                  }
              }
              ).then((response) => {
                if (response.data) {
                  Swal.fire({
                    title: this.$t("t_success_import_data_title"),
                    icon: 'success',
                    timer:2000,
                  })
                  this.checkUserdataInEs=false
                  this.getItem()
                } 
                
              })
            }
            

            
          }

        },
        validateEmail(email) {
          if(email){
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {  // eslint-disable-line
              return true
            } else {
              return false
            }
          } else {
            return false
          }

        },
        checkPartitaIVA(pi) {
          if (pi == '') {
          return false
          } 
          else if (!/^[0-9]{11}$/.test(pi))
          {
          return false
          } else {
          return true
          }
        },
        cancelImportData(){
          this.checkUserdataInEs = false
        },
        Capitalize(value){
          if(value) {
            return value.toUpperCase()
          }
        },    
    },
    mounted() {
      
    },
    components: {

    },
  };
</script>

<template>
<!-- MODAL anagrafica utente -->
  <a href="#addModal" data-bs-toggle="modal" class="btn btn-success add-btn me-1" @click="initItem" >
    <i class="ri-add-line align-bottom me-1"></i>  <i class="ri-user-3-line align-bottom me-1"></i> Aggiungi anagrafica
  </a>
  <div>

  <div class="modal fade" id="addModal" tabindex="-1" aria-hidden="true">
              <div class="modal-dialog   modal-fullscreen  modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header  bg-dark p-3 pl-5 pr-5">
                    <h5 id="addModalLabel">
                      Aggiungi anagrafica cliente
                    </h5>
                    <button type="button" class="btn-clos" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                  </div>
                  <form class="needs-validation" @submit.prevent="updatedata" id="addform">
                    <div class="modal-body">
                      <div class="row rowSearchFormBGce ">
                        <div class="col-md-12 col-lg-12 mt-25">
                          <template v-if="registerSuccess">
                            <b-alert show variant="secondary" class="alert-borderless " role="alert">
                              Inserisci i <strong> dati angrafici </strong> del <strong>nuovo cliente</strong>, per favore.
                            </b-alert>
                          </template>
                          <template v-if="regError==true">
                            <b-alert show variant="danger" class="alert-borderless " role="alert">
                            <strong>Ops, qualcosa è andato storto! </strong>Prova a chiudere la finestra e ripetere l'operazione, per favore.
                            </b-alert>
                          </template>
                         
                        </div>
                      </div>
                      <div class="row rowSearchFormBGce">
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <label for="TipoSoggetto" class="form-label">Tipologia cliente <span class="text-danger">*</span></label>
                            <select class="form-select" required aria-label="Tipologia cliente" v-model="dataEdit.TipoSoggetto" :class="{
                            'is-invalid': submitted && v$.dataEdit.TipoSoggetto.$error,
                          }">
                              <option value="">Seleziona Tipologia cliente</option>
                              <option value="1">Società di Capitali</option>
                              <option value="2">Società di persone</option>
                              <option value="3">Persona fisica</option>
                              <option value="4">PA</option>
                              <option value="5">Altro</option>
                            </select>
                              <div v-for="(item, index) in v$.dataEdit.TipoSoggetto.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-1">
                          <div class="mb-3">
                              <label for="CodCliFor" class="form-label">Codice Cliente</label>
                                <input type="text" class="form-control" v-model="dataEdit.CodCliFor" id="CodCliFor" readonly/>
                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-1">
                          <div class="mb-3">
                              <label for="CodCliFor" class="form-label">Progr.</label>
                               <div class="form-control"><strong>{{ dataEdit.code }}</strong></div>
                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-3" >
                          <div class="mb-3">
                            <label for="nomeFascicolo" class="form-label">Nome Fascicolo (Cartella)</label>
                              <div class="form-control-green1"><strong>{{ dataEdit.nomeFascicolo }}</strong></div>
                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-3" >
                          <div class="mb-3">
                            <label for="CodCondizPagamento" class="form-label">Modalità di pagamento</label>
                              <select class="form-select" v-model="dataEdit.CodCondizPagamento" :class="{
                                  'is-invalid': submitted && v$.dataEdit.CodCondizPagamento.$error,
                                }">
                                <template v-if="optionsModPayConfigList.length>0">
                                  <option v-for="item in optionsModPayConfigList"  :value="item.CodCondizPagamento" :key="item"> {{ item.Des }}</option>
                                </template>
                              </select>
                              <div v-for="(item, index) in v$.dataEdit.CodCondizPagamento.$errors" :key="index" class="invalid-feedback">
                                <span v-if="item.$message">{{ item.$message }}</span>
                              </div>
                          </div>  
                        </div>
                      </div>
                      <div class="row rowSearchFormBGce">
                        <div class="col-md-12 col-lg-2">
                          <div class="mb-3">
                            <template v-if="dataEdit.TipoSoggetto=='3' || dataEdit.TipoSoggetto=='2'">
                              <label for="nome" class="form-label">Nome <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="dataEdit.nome" id="nome" :class="{
                                    'is-invalid': submitted && v$.dataEdit.nome.$error,
                                  }" placeholder="Inserisci Nome cliente" required />
                                <div v-for="(item, index) in v$.dataEdit.nome.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </template>
                            <template v-else>
                              <label for="ragSoc" class="form-label">Ragione sociale <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="dataEdit.ragSoc" id="ragSoc" :class="{
                                    'is-invalid': submitted && v$.dataEdit.ragSoc.$error,
                                  }" placeholder="Inserisci Ragione Sociale" required />
                                <div v-for="(item, index) in v$.dataEdit.ragSoc.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </template>

                            </div>  
                        </div>
                        <div class="col-md-12 col-lg-2" v-if="dataEdit.TipoSoggetto=='3' || dataEdit.TipoSoggetto=='2'">
                          <div class="mb-3">
                            <label for="cognome" class="form-label">Cognome  <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" v-model="dataEdit.cognome" id="cognome" :class="{
                                    'is-invalid': submitted && v$.dataEdit.cognome.$error,
                                  }" placeholder="Inserisci Cognome" required />
                                <div v-for="(item, index) in v$.dataEdit.cognome.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                          </div>  
                        </div>
                        <div class="col-md-12 col-lg-2" >
                          <div class="mb-3">
                            <template v-if="dataEdit.TipoSoggetto=='3' || dataEdit.TipoSoggetto=='2'">
                              <label for="codiceFiscale" class="form-label">Codice Fiscale  <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="dataEdit.codiceFiscale" id="codiceFiscale" :class="{
                                    'is-invalid': submitted && v$.dataEdit.codiceFiscale.$error,
                                  }" placeholder="Inserisci Codice Fiscale" @blur="controllaCF(dataEdit.codiceFiscale)"  required />
                                <div v-for="(item, index) in v$.dataEdit.codiceFiscale.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </template>
                            <template v-else>
                              <label for="codiceFiscale" class="form-label">Codice Fiscale</label>
                              <input type="text" class="form-control" v-model="dataEdit.codiceFiscale" id="codiceFiscale" @blur="controllaCF(dataEdit.codiceFiscale)" />
                            </template>
                            <div id="errCodFisc" class="text-danger"></div>
                          </div>  
                        </div>
                        <div class="col-md-12 col-lg-2" >
                          <div class="mb-3">
                            <template v-if="dataEdit.TipoSoggetto=='3'">
                              <label for="partitaIva" class="form-label">Partita Iva </label>
                              <input type="text" class="form-control" v-model="dataEdit.partitaIva" id="partitaIva" @blur="controllaPartitaIVA(dataEdit.partitaIva)" />
                            </template>
                            <template v-else>
                              <label for="partitaIva" class="form-label">Partita Iva  <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="dataEdit.partitaIva" id="partitaIva" :class="{
                                    'is-invalid': submitted && v$.dataEdit.partitaIva.$error,
                                  }" placeholder="Inserisci Partita Iva" required  @blur="controllaPartitaIVA(dataEdit.partitaIva)"/>
                                <div v-for="(item, index) in v$.dataEdit.partitaIva.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </template>
                            <div id="errPIva"></div>
                          </div>  
                        </div>
                        <div class="col-md-12 col-lg-1" >
                          <div class="mb-3">
                            <label for="codeDest" class="form-label">Codice SDI</label>
                              <input type="text" class="form-control" v-model="dataEdit.codeDest" id="codeDest" placeholder="Codice SDI" />
                          </div>  
                        </div>
                        <div class="col-md-12 col-lg-3" >
                          <div class="mb-3">
                            <label for="iban" class="form-label">IBAN</label>
                              <input type="text" class="form-control" v-model="dataEdit.iban" id="iban" placeholder="Codice IBAN" />
                          </div>  
                        </div>

                      </div>
                       <div class="row rowSearchFormBGce" v-if="dataEdit.TipoSoggetto =='3' || dataEdit.TipoSoggetto=='2'">
                          <div class="col-md-12 col-lg-2">
                              <div class="mb-3">
                                  <label for="dataDiNascita-field" class="form-label">Data di Nascita</label>
                                  <input type="date" id="dataDiNascita" v-model="dataEdit.dataDiNascita" class="form-control" placeholder="Inserisci Codice destinatario" /> 
                              </div>
                          </div>
                          <div class="col-md-12 col-lg-2">
                              <div class="mb-3">
                                  <label for="luogoDiNascita-field" class="form-label">Luogo di Nascita</label>
                                  <input type="text" id="luogoDiNascita" v-model="dataEdit.luogoDiNascita" class="form-control" placeholder="Inserisci Luogo di nascita" />
                              </div>
                          </div>
                       </div>
                       <div class="row rowSearchFormBGce">
                        <div class="col-md-12 col-lg-3">
                            <div class="mb-3"> 
                              <label for="indirizzo" class="form-label">Indirizzo  <span class="text-danger">*</span></label>
                              <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="dataEdit.indirizzo" id="indirizzo" :class="{
                                    'is-invalid': submitted && v$.dataEdit.indirizzo.$error,
                                  }" placeholder="Inserisci Indirizzo" required />
                                <div v-for="(item, index) in v$.dataEdit.indirizzo.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                                <div class="input-group-append"><span class="input-group-text"><i class="ri-home-gear-line"></i></span></div>
                              </div> 
                            </div>                    
                        </div>
                        <div class="col-md-12 col-lg-2">
                            <div class="mb-3">
                                <label for="citta" class="form-label">Città  <span class="text-danger">*</span></label>
                              <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="dataEdit.citta" id="citta" :class="{
                                    'is-invalid': submitted && v$.dataEdit.citta.$error,
                                  }" placeholder="Inserisci Città" required />
                                <div v-for="(item, index) in v$.dataEdit.citta.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                                <div class="input-group-append"><span class="input-group-text"><i class="ri-community-fill"></i></span></div>
                              </div> 
                            </div>                                              
                        </div>
                        <div class="col-md-12 col-lg-1">
                            <div class="mb-3">
                                <label for="cap" class="form-label">CAP  <span class="text-danger">*</span></label>
                              <div class="input-group mb-3">
                                <input type="text" class="form-control" v-model="dataEdit.cap" id="cap" :class="{
                                    'is-invalid': submitted && v$.dataEdit.cap.$error,
                                  }" placeholder="CAP" required />
                                <div v-for="(item, index) in v$.dataEdit.cap.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                                <div class="input-group-append"><span class="input-group-text"><i class="ri-home-6-fill"></i></span></div>
                              </div> 

                            </div>                           
                        </div>
                        <div class="col-md-12 col-lg-1">
                          <div class="mb-3">
                                <label for="cap" class="form-label">Prov. (Sigla) <span class="text-danger">*</span></label>
                              <div class="input-group mb-3">
                                <input type="text" class="form-control w-90" v-model="dataEdit.provincia" id="provincia" :class="{
                                    'is-invalid': submitted && v$.dataEdit.provincia.$error,
                                  }" placeholder="Provincia" maxlength="2" @blur="Capitalize(dataEdit.provincia)"  required />
                                <div v-for="(item, index) in v$.dataEdit.provincia.$errors" :key="index" class="invalid-feedback">
                                  <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                                <div class="input-group-append"><span class="input-group-text"><i class="ri-community-fill"></i></span></div>
                              </div> 
                            </div>
                        </div>
                       </div>
                       <div class="row rowSearchFormBGce"  v-if="checkUserdataInEs==false">
                            <div class="col-md-12 col-lg-2">
                                <div class="mb-3">
                                  <label for="email" class="form-label">Email  <span class="text-danger">*</span></label>
                                    <div class="input-group mb-3">
                                      <input type="text" class="form-control" v-model="dataEdit.email" id="email" :class="{
                                          'is-invalid': submitted && v$.dataEdit.email.$error,
                                        }" placeholder="Email" required />
                                      <div v-for="(item, index) in v$.dataEdit.email.$errors" :key="index" class="invalid-feedback">
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                      </div>
                                      <div class="input-group-append"><span class="input-group-text"><i class="ri-mail-unread-line"></i></span></div>
                                    </div> 
                                </div>                  
                            </div>
                            <div class="col-md-12 col-lg-2">
                                <div class="mb-3">
                                    <label for="email-field" class="form-label">PEC</label>
                                    <div class="input-group mb-3">
                                      <input type="email" id="pec" v-model="dataEdit.pec"  class="form-control" placeholder="Inserisci PEC" required />
                                      <div class="input-group-append"><span class="input-group-text"><i class="ri-mail-unread-line"></i></span></div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-2">
                                <div class="mb-3">
                                    <label for="telefono" class="form-label">Telefono<span class="text-danger">*</span></label>
                                    <div class="input-group mb-3">
                                      <input type="number" class="form-control" v-model="dataEdit.telefono" id="telefono" :class="{
                                          'is-invalid': submitted && v$.dataEdit.telefono.$error,
                                        }" placeholder="Inserisci numero tel./cell." required />
                                      <div v-for="(item, index) in v$.dataEdit.telefono.$errors" :key="index" class="invalid-feedback">
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                      </div>
                                      <div class="input-group-append"><span class="input-group-text"><i class="ri-phone-line"></i></span></div>
                                    </div> 
                                </div>                            
                            </div>
                        </div>
                        <div class="row rowSearchFormBGce"  v-if="checkUserdataInEs==true">
                            <div class="col-md-12 col-lg-12 text-center rowWhite">
                              <h3>{{ $t("t_check_res_title") }}</h3>
                            </div>
                        </div>
                        <div class="row rowSearchFormBGce" v-if="checkUserdataInEs==true">
                            <div class="col-md-12 col-lg-3 text-center"></div>
                            <div class="col-md-12 col-lg-6 text-center rowWhite">
                              <h4>{{ $t("t_check_res_text") }}</h4>
                              
                              <b-alert show variant="primary">
                                <h4><strong>{{ retrCode }}</strong></h4>
                              </b-alert>
                            </div>
                            <div class="col-md-12 col-lg-3 text-center"></div>
                        </div>
                        <div class="row rowSearchFormBGce" v-if="checkUserdataInEs==true">
                            <div class="col-md-12 col-lg-5 text-center"></div>
                            <div class="col-md-12 col-lg-2 text-center">
                              <input type="number" class="form-control" v-model="codiceConfermato" maxlength="5" />
                            </div>
                            <div class="col-md-12 col-lg-5 text-center"></div>
                        </div>
                        <div class="row rowSearchFormBGce mt-10" v-if="checkUserdataInEs==true">
                            <div class="col-md-12 col-lg-5 text-center"></div>
                              <div class="col-md-12 col-lg-1 text-center">
                                <b-button type="button" variant="primary" @click="swlDataImport">
                                  <i class="ri-rocket-line align-bottom me-1"></i>
                                  Importa dati
                                </b-button>
                              </div>
                              <div class="col-md-12 col-lg-1 text-center">
                                <b-button variant="outline-secondary" class="waves-effect waves-light" @click="cancelImportData">Annulla</b-button>
                              </div>
                              <div class="col-md-12 col-lg-5 text-center"></div>
                          </div>
                          <template v-if="checkUserdataInEs==false">
                          <div class="row mt-50">
                            <div class="col-md-12 col-lg-12 text-center">
                              <b-button type="submit" variant="primary" size="lg">
                                <i class="ri-save-line align-bottom me-1"></i>
                                Registra anagrafica
                              </b-button>
                            </div>
                          </div>
                        </template>                      
                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" id="edtclosemodal" class="btn btn-light" data-bs-dismiss="modal">
                          Chiudi
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
</template>