<script>

import serverConfig   from '@/helpers/config';
import { ref } from "vue";
import customerSearchModel from "@/components/widgets/customerSearchModel";
import detailCustomer from "@/components/widgets/detailCustomer";
import addCustomer from "@/components/widgets/addCustomer";


export default {
  name: "formAnagraficaCliente",
  components:{
	customerSearchModel,
	detailCustomer,
	addCustomer,
  },
  props: [
    'projectID',
    'data',
  ],
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    return { active, toggleActive };
  },
  data() {
	return {
		UrlServer: serverConfig.EP,
        datastore: this.$store.state,
		form: {
			codiceFascicolo:"",
			PartitaIva :"",
			CodFiscale : "",
			TipoSoggetto :"",
			TipoSoggettoRit :"",
			PersonaCognome : "",
			PersonaNome :"",
			PersonaComuneNascita : "",
			PersonaProvNascita : "",
			PersonaStatoNascita : "",
			PersonaDataNascita :"",
		
			RagSoc1 : "",
			RagSoc2 : "",
			Presso : "",
			Indirizzo : "",
			Indirizzo2 : "",
			Cap : "",
			Localita : "",
			Localita2 : "",
			Provincia : "",
			CodStato : "",
			NumTel :"",
			NumTel2 : "",
			NumFax : "",
			IndirEmail : "",
			CodCliFor : "",
			CodUfficioPA : "",
			LuogoDiConsegna : "",
			IndirEmailPECSDI :"",
			detailShipping:{
				Indirizzo2 :"",
				Localita2 :"",
				Cap :"",
				Provincia :"",
				CodStato :"",
				IndirEmail :"",
				NumTel2 :"",
				indirizzoDiSpedizione:'ragsoc1_shipping',
			},
		},
		getTrl:{
			t_check_res_title: 'Esito controllo dati',
			t_check_res_text: 'Abbiamo riscontrato la presenza del tuo codice fiscale(o partita iva) nel nostro sistema. Se sei già in possesso di un codice cliente, potrai utilizzarlo per importare i tuoi dati.',
			t_success_import_data_title:'Dati importati con successo',
			t_error_import_data_title:'Non è stato possibile importare i dati. Assicurati che il codice cliente sia corretto, per favore.',
			t_error_fiscal_code:'Codice fiscale non corretto. Inserisci un codice valido, per favore.',
			t_error_vat_number:'Non hai inserito la Partita Iva, inseriscila, per favore.',
			t_error_vat_number_text:'La partita IVA deve contenere 11 cifre.',
		}
	}
	},
	computed:{ 
		
	},

	methods:{
		getParent(){
			this.$emit('getParent')
		},	
	},
};
</script>
<template>
<!-- Form anagrafica -->
    <form class="vertical-navs-step">
        <div class="row gy-5">
			
            <div class="col-lg-3">
				<div class="nav flex-column custom-nav nav-pills mb-20" role="tablist" aria-orientation="vertical">
                    <button class="nav-link done" id="v-pills-bill-info-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bill-info" type="button" role="tab" aria-controls="v-pills-bill-info" aria-selected="false">
                        <span class="step-title me-2">
                            <i class="ri-close-circle-fill step-icon me-2"></i>
                                Dati di Fatturazione
                        </span>                                        
                    </button>                                      
                </div>
				<div id="boxAnch" class="nav flex-column custom-nav nav-pills mb-10">
					<customerSearchModel :ID="data._id" type="project" v-on:getParent="getParent()"></customerSearchModel>
				</div>
				<div class="nav flex-column custom-nav nav-pills mb-20">
					<addCustomer type="add-customer" :projectID="data._id" :ID="data._id" @getParent="getParent()" />
				</div>

			</div> 
            <div class="col-lg-9">
                <div class="px-lg-4  background-grey-ce">
                   <div class="tab-content pt-2">
                        <div class="tab-pane fade show active" id="v-pills-bill-info" role="tabpanel" aria-labelledby="v-pills-bill-info-tab">
                            <div>
                                <h4>Dati di fatturazione</h4>
                                 <p class="text-muted"></p>
                            </div>
                       <div>
                            <div class="row g-3">
                               <div class="col-sm-12 col-lg-3 pr-20">
                                   <label for="TipoSoggetto" class="form-label">Tipologia cliente</label>
                                   <div class="mb-3 pl-5 bold">
									<span class="input-group-text" >
										<template v-if="data.customerDetails.TipoSoggettoDesc">
											{{data.customerDetails.TipoSoggettoDesc}}
										</template>
									</span>
									</div>
                                </div>

								<div class="col-md-12 col-lg-6">
									<label for="zip" class="form-label">Nome Cartella cliente</label>
									<div class="mb-3 pl-5 bold input-group-text">
										
										<template v-if="data.customerDetails.nomeFascicolo">
											{{data.customerDetails.nomeFascicolo}}
										</template>
									</div>
								</div>
								<div class="col-md-12 col-lg-2">
									<label for="CodCliFor" class="form-label">Codice Cliente*</label>
									<div class="mb-3 pl-5 bold input-group-text">
										
											<template v-if="data.customerDetails.CodCliFor">
												{{data.customerDetails.CodCliFor}}
											</template>
										
									</div>									
                                </div>
                           </div>   
							<template v-if="data.TipoSoggetto == 'Persona fisica'">
								<div class="row g-3">
									<div class="col-sm-6 col-lg-6">
										<label for="personaNome" class="form-label">Nome</label>
										<div class="mb-3 pl-5 bold input-group-text">
											<template v-if="data.customerDetails.nome">
												{{data.customerDetails.nome}}
											</template>
										</div>
									</div>	
									<div class="col-sm-6 col-lg-6">
										<label for="personaCognome" class="form-label">Cognome</label>
										<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.cognome">
											{{data.customerDetails.cognome}}
										</template>	
										</div>					
									</div>
								</div>	
								<div class="row g-3">
									<div class="col-sm-6">
										<label for="personaDataNascita" class="form-label">Data di Nascita</label>
										<div class="mb-3 pl-5 bold input-group-text"></div>
									</div>	
									<div class="col-sm-6">
										<label for="PersonaComuneNascita" class="form-label">Comune di nascita</label>
										<div class="mb-3 pl-5 bold input-group-text"></div>
									</div>
								</div>


								<div class="row g-3">
									<div class="col-sm-6">
										<label for="PersonaStatoNascita" class="form-label">Stato di Nascita</label>
										<div class="mb-3 pl-5 bold input-group-text"></div>
											<!--<country-select 
												v-model="data.PersonaStatoNascita" 
												:country="data.PersonaStatoNascita" 
												topCountry="IT"  
												placeholder="Seleziona Stato"  
												class="form-select"
												usei18n="true"
											/>
											-->
									</div>	
									<div class="col-sm-6">
										<label for="PersonaProvNascita" class="form-label">Provincia di nascita (solo sigla, esempio: SS)</label>
										<div class="mb-3 pl-5 bold input-group-text"></div>
									</div>
								</div>
							</template>
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="CodFiscale" class="form-label">Codice Fiscale</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.codiceFiscale">
											{{data.customerDetails.codiceFiscale}}
										</template>
									</div>
								</div>
								<div class="col-sm-6">
									<label for="PartitaIva" class="form-label">Partita Iva</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.partitaIva">
											{{data.customerDetails.partitaIva}}
										</template>
									</div>						
								</div>								
							</div>
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="ragSoc1" class="form-label">Ragione Sociale</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.ragSoc">
											{{data.customerDetails.ragSoc}}
										</template>
									</div>	
								</div>
								<div class="col-sm-6">
									<label for="Indirizzo" class="form-label">Indirizzo</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.indirizzo">
											{{data.customerDetails.indirizzo}}
										</template>
									</div>								
								</div>
                            </div>
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="Localita" class="form-label">Comune</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.citta">
											{{data.customerDetails.citta}}
										</template>
									</div>											
								</div>
								<div class="col-sm-6">
									<label for="Cap" class="form-label">CAP</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.cap">
											{{data.customerDetails.cap}}
										</template>
									</div>	
								</div>
                            </div>
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="Provincia" class="form-label">Provincia (sigla)</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.provincia">
											{{data.customerDetails.provincia}}
										</template>
									</div>	
								</div>
								<div class="col-sm-6">
									<label for="CodStato" class="form-label">Stato</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.CodStato">
											{{data.customerDetails.CodStato}}
										</template>
									</div>	
								</div>
                            </div>	
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="IndirEmail" class="form-label">E-mail</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.email">
											<a :href="'mailto:'+data.customerDetails.email">{{data.customerDetails.email}}</a>
										</template>
									</div>	 
								</div>
								<div class="col-sm-6">
									<label for="NumTel" class="form-label">Numero di telefono</label>
										<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.telefono">
											{{data.customerDetails.telefono}}
										</template>
									</div>	 
								</div>
                            </div>
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="CodUfficioPA" class="form-label">Codice Univoco</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.codeDest">
											{{data.customerDetails.codeDest}}
										</template>
									</div>	 
								</div>
								<div class="col-sm-6">
									<label for="IndirEmailPECSDI" class="form-label">PEC</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.pec">
											<a :href="'mailto:'+data.customerDetails.pec">{{data.customerDetails.pec}}</a>
										</template>
									</div>	 
								</div>
                            </div>
							<div class="row g-3">
								<div class="col-sm-6">
									<label for="CodUfficioPA" class="form-label">Modalità di pagamento</label>
									<div class="mb-3 pl-5 bold input-group-text">
										<template v-if="data.customerDetails.DesCondizPagamento">
											{{data.customerDetails.DesCondizPagamento}}
										</template>
									</div>	 
								</div>
								<div class="col-sm-6">
									<label for="IndirEmailPECSDI" class="form-label">IBAN</label>
									<div class="mb-3 pl-5 bold input-group-text"  v-if="data.customerDetails.iban">
										{{data.customerDetails.iban}}
									</div>
									<div class="mb-3 pl-5 bold input-group-text-danger"  v-else>
										{{data.customerDetails.iban}}
									</div>	 
								</div>
                            </div>	
						</div>
                                                           
					</div>

					</div>

						<div class="d-flex align-items-start gap-3 mt-50 mb-20 pb-20">	
							<template v-if="data">
								<template v-if="data.customerDetails && data.customerDetails._id">
									<detailCustomer :ID="data.customerDetails._id" :projectID="projectID" type="project" v-on:getParent="getParent()" />
								</template>
							</template>
						</div> 
                    </div>
                </div>
                <!-- end col -->
            </div>
        <!-- end row -->
    </form>
	<!-- / Form anagrafica -->
</template>