<script>
import axios from 'axios'
import { ref, computed } from "vue";
import { useCookies } from "vue3-cookies";
import serverConfig from '@/helpers/config';
import Swal from "sweetalert2";

export default {
  name: "customerSearchModel",
  emits: ["getParent"], 
  props: [
    'ID',
    'type',
    'data',
  ],
  components:{
   
  },
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };
    const { cookies } = useCookies();   
    let tk = 'Bearer '+cookies.get('tk')
    let searchTerm = ref('')
    let qSearch = ''
    let countries = []


    const fullSearch = async () =>
    {
      axios.get(`${serverConfig.EP}customers/search/full/text`,
        { 
          params: { 
            q: qSearch,
           
          },
          headers: { 'authorization':tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          countries = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }


    const searchCountries = computed(() => {
      if (searchTerm.value === '') {
        return []
      }
      let matches = 0
      return countries.filter(country => {
        if (country.name.toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
          matches++
          return country
        }
      })
    });
    const selectCountry = (country) => {
      selectedCountry.value = country
      searchTerm.value = ''
    }
    let selectedCountry = ref('')

    return { 
      fullSearch,
      searchTerm,
      searchCountries,
      selectCountry,
      selectedCountry,
      active, 
      toggleActive, 
      tk 
    };
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      fileStorage:[],
      uploadValue:0,
      fileList:null,
      fileData:[],
      file:'',
      bucket:'',
      dataEdit:{},
      qSearch:'',
      resultsList:[],
      
    }
  },
  mounted(){
   

  },
  methods:{
    async fullSearch2(){
      axios.get(`${this.UrlServer}customers/search/full/text`,
        { 
          params: { 
            q: this.qSearch,
          },
          headers: { 'authorization':this.tk} 
        }
      )
      .then((response) => {
        if (response.data)  {
          this.resultsList = response.data
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    selectCustomer(params){
      this.updateItem(params)
    },
    updateItem(params){
        let data = {}
        data.customerID = params._id
        data.customerDetails = params
        
        axios.put(`${this.UrlServer}projects/customer-details/${this.ID}`, data, {headers: { authorization:this.tk}} )
          .then((response)=>{
            
            if (response.data) {
              if (response.data.modifiedCount==1) {
                Swal.fire(  this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
                this.closeModal()
              } else {
                Swal.fire(  this.$t("t-msg-partially-updated-app") , this.$t("t-msg-partially-updated-content-app"), "warning");
              }
            }
            
          })
          .catch(()=>{
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
          });
      },
    openModal(){
        document.getElementById("editmodal").click();
    },
    closeModal(){
      document.getElementById("close-modal").click();
    },  
  }
};
</script>
<template>
<div>
  <a href="#searchUsermodal" data-bs-toggle="modal" class="btn btn-primary add-btn me-1 white-color">
    <i class="ri-search-line align-bottom me-1"></i> Seleziona cliente
  </a>
  <div class="modal fade" id="searchUsermodal" data-bs-backdrop="static"  tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
                  <div class="modal-header bg-dark p-3 pl-5 pr-5">
                    <h5  id="searchUserModalLabel">
                     Associa anagrafica utente al documento
                    </h5>
                    <button type="button" class="btn-clos" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal">
                    
                    </button>
                  </div>
                  <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />
                      <div class="mb-3" id="modal-id" style="display: none">
                        <label for="id-field1" class="form-label">ID</label>
                        <input type="text" id="edtidfield1" class="form-control" placeholder="ID" readonly />
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-lg-12">
                          <div class="mb-3">
                           
                              <input
                                type="text"
                                id="search"
                                v-model="qSearch"
                                placeholder="Digita qui termine di ricerca..."
                                class="form-control-search p-3 mb-0.5 w-full border border-blue-300 rounded"
                                @input="fullSearch2()"
                              >             
                           </div>
                        </div>
                        <div class="col-md-12 col-lg-12">
                          <div class="mb-3">
                            <ul
                              v-if="resultsList.length"
                              class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
                            >
                              <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
                                Mostra {{ searchCountries.length }} di {{ resultsList.length }} risultati
                              </li>
                              <li
                                  v-for="item in resultsList"
                                  :key="item._id"
                                  @click="selectCustomer(item), $emit('getParent')"
                                  class="cursor-pointer hover:bg-gray-100 p-1"
                              >
                              {{ item.ragSoc }} 
                              <template v-if="item.nome && item.nome!=''">
                                 - {{ item.nome }}  
                              </template>
                              <template v-if="item.cognome && item.cognome!=''">
                                {{ item.cognome }} 
                              </template>
                              </li>
                            </ul>

                            <p
                              v-if="selectedCountry"
                              class="text-lg pt-2 absolute"
                            >
                              You have selected: <span class="font-semibold">{{ selectedCountry }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>



